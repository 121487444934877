
















































import { Component, Vue } from 'vue-property-decorator';
import UserService from '@/services/UserService';

@Component
export default class Login extends Vue {
  protected username = '';
  protected password = '';

  protected loginState: boolean | null = null;
  protected loading = false;

  protected usernameElement: HTMLElement | null = null;
  protected passwordElement: HTMLElement | null = null;
  protected loginButton: HTMLElement | null = null;

  created(): void {
    localStorage.removeItem('mdre-token');
  }

  mounted(): void {
    this.usernameElement = document.getElementById('username');
    this.passwordElement = document.getElementById('password');
    this.loginButton = document.getElementById('b-login');
  }

  protected forgotPassword(): void {
    this.$router.push('/password/forgot');
  }
  protected login(): void {
    if (this.username.length === 0 || this.password.length === 0) {
      this.loginState = false;
    } else {
      this.loading = true;
      UserService.login(this.username, this.password)
        .then((token) => {
          localStorage.setItem('mdre-token', token.data);
          this.$router.replace({ name: 'projects' }).then(() => {
            this.$root.$emit('user-change', { token: token.data.split(' ')[1] });
          });
        })
        .catch((backendError) => {
          if (backendError.response.status === 401) {
            this.loginState = false;
          } else {
            this.$bvToast.toast('A server error occurred. Please try again later.', {
              title: 'Action failed',
              variant: 'danger',
              solid: true,
              noAutoHide: true,
            });
          }
        })
        .finally(() => (this.loading = false));
    }
  }

  protected triggerLogin(event: KeyboardEvent): void {
    // either click on login button or enter on one of the input fields
    if (event.target === this.loginButton || (event.code && event.code.toLowerCase() === 'enter')) {
      this.$bvToast.hide();
      if (event && event.target === this.usernameElement) {
        if (this.passwordElement) {
          this.passwordElement.focus();
        }
      } else if (this.username.length === 0 || this.password.length === 0) {
        this.loginState = false;
      } else {
        this.login();
        if (this.passwordElement) {
          this.passwordElement.blur();
        }
        if (this.loginButton) {
          this.loginButton.focus();
        }
      }
    }
  }
}
